import React, { Component } from "react";
import cx from "classnames";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PATHS from "./routes";
import "./App.scss";

// Pages.
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Terms from "./pages/Terms";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";

class App extends Component {
  render() {
    return (
      <Router>
        <div className={cx("App", "root")}>
          <div className="root-content">
            <Switch>
              <Route exact path={PATHS.home} component={Home} />
              <Route exact path={PATHS.terms} component={Terms} />
              <Route exact path={PATHS.cookiePolicy} component={CookiePolicy} />
              <Route
                exact
                path={PATHS.privacyPolicy}
                component={PrivacyPolicy}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
