export default {
  GLOBAL: {
    SITENAME: 'Scales Assistant',
    FOOTER: {
      COPYRIGHT_TEXT: '© 2019 4 All Digital ltd. All rights reserved.',
    },
    NAVIGATION: {
      UTIL: {
        ABOUT: 'About',
        REGISTER: 'Register',
        PRIVACY_POLICY: 'Privacy Policy',
        COOKIE_POLICY: 'Cookie Policy',
      },
      ADMIN: {
        SETTINGS: 'Settings',
        TERMS: 'Terms & Conditions',
        LOGIN: 'Login',
        LOGOUT: 'Log Out',
      },
    },
  },
  PAGES: {
    HOME: {
      TITLE: 'Scales Assistant',
      INTRO: 'Welcome to Scales Assistant',
    },
    ABOUT: {
      TITLE: 'About',
      INTRO:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.',
    },
    COOKIE_POLICY: {
      TITLE: 'Cookie Policy',
      INTRO:
        'This is the Cookie Policy for Scales Assistant, accessible from http://www.oyg.com.',
    },
    PRIVACY_POLICY: {
      TITLE: 'Privacy Policy',
      INTRO:
        'This is the Privacy Policy for Scales Assistant, accessible from http://www.oyg.com.',
    },
    TERMS_CONDITIONS: {
      TITLE: 'Terms & Conditions',
      INTRO:
        'This is the terms and conditions page for Scales Assistant, accessible from http://www.oyg.com.',
    },
    NOT_FOUND: {
      TITLE: 'Page Not Found',
      INTRO:
        'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.',
    },
  },
  UI: {
    SUBMIT: 'Submit',
    LOGIN: 'Login',
    REGISTER: 'Register',
    CANCEL: 'Cancel',
    SOCIAL: {
      GOOGLE: 'Google',
      FACEBOOK: 'Facebook',
      TWITTER: 'Twitter',
      EMAIL: 'Email',
    },
    READ_MORE_LABEL: 'Read more',
    READ_LESS_LABEL: 'Show less',
    CLOSE_LABEL: 'Close',
    BACK_LABEL: 'Back',
    SHARE_LABEL: 'Share',
    FORMAT_HOUR: '{hour}hr',
    FORMAT_HOURS: '{hours}hrs',
    FORMAT_MINUTE: '{minute}min',
    FORMAT_MINUTES: '{minutes}mins',
    LOAD_MORE_LABEL: 'Load more',
    NO_MORE_RESULTS: "That's all folks.",
    UNKNOWN_ERROR_TITLE: 'Unknown error',
    UNKNOWN_ERROR_MESSAGE: 'Sorry, something bad happened! Please try again.',
    TOO_MANY_REQUESTS_MESSAGE:
      'Sorry, There have too many requests. please wait a minute and try again.',
    CONTENT_SORT_TITLE: 'Sort by',
    CONTENT_FILTERS_TITLE: 'Filters',
    CONTENT_FILTERS_LABEL: 'Filter',
    CLOSE_FILTERS_LABEL: 'Done',
    CLEAR_ALL_FILTERS_LABEL: 'Clear All',
    CLEAR_FILTERS_LABEL: 'Clear',
    MORE_ITEMS_LABEL: 'Next items',
    PREVIOUS_ITEMS_LABEL: 'Previous items',
    CHOOSE_BTN_LABEL: 'Choose',
    ADD_BTN_LABEL: 'Add',
    SEND_BTN_LABEL: 'Send',
    CANCEL_BTN_LABEL: 'Cancel',
    DONE_BTN_LABEL: 'Done',
    EDIT_BTN_LABEL: 'Edit',
    CHANGE_BTN_LABEL: 'Change',
    REMOVE_BTN_LABEL: 'Remove',
    MESSAGE_PROMPT_BTN_LABEL: 'Add one',
    SELECT_FILE_BTN_LABEL: 'Select file',
  },
};
