import React, { Fragment } from "react";
import cx from "classnames";
import Logo from "../Logo";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <Fragment>
      <header className={cx("app-header", styles.headerwrapper)}>
        <Logo />
      </header>
    </Fragment>
  );
};

export default Header;
