import React from "react";
import { shape, node, string } from "prop-types";
import DocumentTitle from "react-document-title";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Header from "../Header";
import Footer from "../Footer";
import PageContext from "../../pages/PageContext";
import strings from "../../translations";
import styles from "./Page.module.scss";

const { SITENAME } = strings.GLOBAL;

const Page = ({ children, title, location: { state, pathname } }) => {
  const cx = classNames([styles["page"]], {
    page: true,
    "page--prev": state && state.prev,
  });

  const context = {
    path: pathname,
  };

  return (
    <DocumentTitle title={`${title} | ${SITENAME}`}>
      <PageContext.Provider value={context}>
        <Header pathname={pathname} />
        <main className="root-content">
          <section id="page" className={cx}>
            {children}
          </section>
        </main>
        <Footer />
      </PageContext.Provider>
    </DocumentTitle>
  );
};

Page.propTypes = {
  children: node.isRequired,
  location: shape({}).isRequired,
  title: string.isRequired,
};

export default withRouter(Page);
