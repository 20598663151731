import React from "react";
import styles from "./Footer.module.scss";
import strings from "../../translations";

const { COPYRIGHT_TEXT } = strings.GLOBAL.FOOTER;

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div></div>
      <div>{COPYRIGHT_TEXT}</div>
    </footer>
  );
};

export default Footer;
