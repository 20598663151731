import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { node, func, oneOf } from "prop-types";

// A simple component that shows the pathname of the current location
class RouterLink extends Component {
  static propTypes = {
    children: node,
    onClick: func,
    type: oneOf(["Link", "Button"]),
  };

  static defaultProps = {
    children: null,
    onClick: null,
    type: "Link",
  };

  constructor() {
    super();

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler() {
    const { onClick } = this.props;
    if (typeof onClick === "function") {
      onClick(this.props);
    }
  }

  render() {
    const { children, className, href } = this.props;
    return (
      <a href={href} className={className} onClick={this.changeHandler}>
        {children}
      </a>
    );
  }
}

export default withRouter(RouterLink);
