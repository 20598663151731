import React from 'react';
import { ReactComponent as LogoSvg } from './logo.svg';
import Link from '../../ui/RouterLink';
import styles from './Logo.module.scss';

const Logo = () => {
  return (
    <Link href="/" className="logo-link">
      <LogoSvg className={styles.logo} />
    </Link>
  );
};

export default Logo;
